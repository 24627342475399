import React, { createContext, useContext } from 'react';

import { SplitContext, useTreatments } from '@splitsoftware/splitio-react';
import { ISplitContextValues } from '@splitsoftware/splitio-react/types/types';
import { feature_2, feature_6, feature_7 } from '../../splitSdkConfig';
import { useState } from 'react';
import { useEffect } from 'react';

export interface UserInfo {
  email: string;
  is_admin: string;
  organization_name: string;
}

export interface FeatureFlagContextType {
  isReady: boolean;
  ctrlTreatments: SplitIO.TreatmentsWithConfig;
  videoTreatments: SplitIO.TreatmentsWithConfig;
  setUserBelongsTo: (user: UserInfo) => void;
  userBelongsTo: UserInfo | undefined;
  featureFlags: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlagContextType>(null!);

export default function FeatureFlagsProvider(props: { children: React.ReactNode }) {
  const { isReady }: ISplitContextValues = useContext(SplitContext);
  const [userBelongsTo, setUserBelongsTo] = useState<UserInfo | undefined>();
  const [featureFlags, setFeatureFlags] = useState(
    !isReady ? (process.env.REACT_APP_FEATURE_FLAGS_DEFAULT === 'on' ? true : false) : isReady
  );

  const ctrlTreatments: SplitIO.TreatmentsWithConfig = useTreatments([], {
    org: userBelongsTo?.organization_name,
  });

  const videoTreatments: SplitIO.TreatmentsWithConfig = useTreatments([feature_2, feature_6, feature_7], {
    org: window.location.hostname.replaceAll('.', ''),
  });

  useEffect(() => {
    if (!isReady) setFeatureFlags(process.env.REACT_APP_FEATURE_FLAGS_DEFAULT === 'on' ? true : false);
    if (isReady) setFeatureFlags(isReady);
  }, [isReady]);

  return (
    <FeatureFlagsContext.Provider
      value={{
        isReady,
        ctrlTreatments,
        videoTreatments,
        setUserBelongsTo,
        userBelongsTo,
        featureFlags,
      }}
    >
      {props.children}
    </FeatureFlagsContext.Provider>
  );
}

export function useFeatureFlags() {
  const context = useContext(FeatureFlagsContext);
  if (!context) {
    throw new Error('useFeatureFlags must be used within the FeatureFlagsProvider');
  }
  return context;
}
