import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import React from 'react';

import './types';


import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import Plausible from 'plausible-tracker'

import { SplitFactory } from '@splitsoftware/splitio-react';
import splitSdkConfig from './splitSdkConfig';
import FeatureFlagsProvider from './components/FeatureFlagsProvider';

/**
 * Works around CRA webpack limitations by adding an ENV called REACT_APP_PAGE_TARGET
 * to set the entry point within ReactJS. We can have "/" be one of the other flows...
 * e.g., Player or VIBE homepage.
 * 
 * REACT_APP_PAGE_TARGET: event
 * 
 * If set to "event", the build will include the EventAppWrapper for player, etc
 * The default is to load our standard VIBE app with Homepage and Video 
 * 
 * ref: https://blog.logrocket.com/multiple-entry-points-in-create-react-app-without-ejecting/
 * 
 * @returns AppWrapper 
 */ 
function importBuildPageTarget() {

  if (process.env.REACT_APP_PAGE_TARGET === 'event') {
    return import('./EventAppWrapper');
  } else {
    return import('./DefaultAppWrapper');
  }
}

// Using process.env with index syntax to avoid `string | undefined` typescript error

if (
  !(
    process.env.REACT_APP_AUTH0_DOMAIN &&
    process.env.REACT_APP_AUTH0_CLIENT_ID &&
    process.env.REACT_APP_AUTH0_API_AUDIENCE &&
    process.env.REACT_APP_CLOUDINARY_ENDPOINT &&
    process.env.REACT_APP_API_ENDPOINT
  )
) {
  throw new Error('Please define Auth0 and APP_ENV Config and on .env file');
}

if (process.env['REACT_APP_SENTRY_DSN']) {
  console.log('REACT JS, NODE_ENV', process.env['NODE_ENV']);
  console.log('REACT JS, SENTRY_ENV', process.env['REACT_APP_SENTRY_ENV'] || process.env['NODE_ENV']);
  Sentry.init({
    dsn: process.env['REACT_APP_SENTRY_DSN'],
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env['REACT_APP_SENTRY_ENV'] || process.env['NODE_ENV'],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(process.env['REACT_APP_SENTRY_TRACING_RATE'] || '1.0'),
  });
}

/** Renders the wrapper component. See ^^^^ */
importBuildPageTarget().then(({ default: Environment }) =>
  ReactDOM.render(
    <SplitFactory config={splitSdkConfig}>
      <FeatureFlagsProvider>
        <Environment />
      </FeatureFlagsProvider>
    </SplitFactory>,   
  document.getElementById('root')
  )
);

if (process.env.NODE_ENV === 'production') {
  LogRocket.init(process.env['REACT_APP_LOGROCKET_KEY'] !== undefined ? process.env['REACT_APP_LOGROCKET_KEY'] : '3tbkna/vibe');
}

if (process.env.NODE_ENV === 'production') {
  const { enableAutoPageviews } = Plausible( {domain: process.env['REACT_APP_PLAUSIBLE_DOMAIN'] });

  // This tracks the current page view and all future ones as well
  enableAutoPageviews();
}