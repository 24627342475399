// Your SDK settings for browser.
// Replace authorizationKey with your api browser key.
const splitSdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: process.env.REACT_APP_SPLITIO_KEY ? process.env.REACT_APP_SPLITIO_KEY?.toString() : '',
    key: 'key',
  },

  debug: false
};
export default splitSdkConfig;

// Current splits
export const feature_2 = 'live_streams';
export const feature_6 = 'video_room_monitor';
export const feature_7 = 'matte_test';
